import { type FC, type ReactNode } from 'react';

import { getImageSrc } from 'dibs-buyer-layout/exports/imageHelpers';

import { ExpandableContent } from '../../components/global/ExpandableContent/ExpandableContent';

import styles from './SbRespContentModuleBase.scss';

type Props = {
    description: string | null;
    title: string | null;
    photo: string | null;
    className?: string;
    children: ReactNode;
};

export const SbRespContentModuleBase: FC<Props> = ({
    description,
    title,
    photo,
    className,
    children,
}) => {
    const photoSrc = getImageSrc(photo, {
        width: 220,
        height: 220,
        fit: 'crop',
    });

    const MODULE_HEIGHT = title ? 130 : 120;

    return (
        <div className={`${styles.info} ${className}`}>
            {photo && (
                <img
                    className={styles.photo}
                    src={photoSrc || ''}
                    data-tn="facet-creator-picture"
                    alt={title || ''}
                />
            )}
            <ExpandableContent
                collapseHeight={MODULE_HEIGHT}
                dataTn="facet-creator-info"
                showReadMoreArrow={false}
                wrapperClassName={styles.expandableWrapper}
            >
                <div className={styles.content}>
                    {title && (
                        <div className={styles.title} data-tn="facet-creator-title">
                            {title}
                        </div>
                    )}
                    <span
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: description || '' }}
                        data-tn="facet-creator-description"
                    />
                    {children}
                </div>
            </ExpandableContent>
        </div>
    );
};
