import { type FC } from 'react';
import { type SbSharedStorefrontLink_browseContentModule$data } from './__generated__/SbSharedStorefrontLink_browseContentModule.graphql';

import { SbSharedStorefrontLinkBase } from './SbSharedStorefrontLinkBase';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

type Props = {
    browseContentModule: SbSharedStorefrontLink_browseContentModule$data;
};

export const SbSharedStorefrontLinkComponent: FC<Props> = ({ browseContentModule }) => {
    return <SbSharedStorefrontLinkBase browseContentModule={browseContentModule} />;
};

export const SbSharedStorefrontLink = createFragmentContainer(SbSharedStorefrontLinkComponent, {
    browseContentModule: graphql`
        fragment SbSharedStorefrontLink_browseContentModule on ContentModuleRegular {
            seller {
                sellerProfile {
                    company
                }
                sellerPreferences {
                    urlLabel
                }
                liveStorefrontProfile {
                    status
                }
            }
        }
    `,
});
