/**
 * @generated SignedSource<<8cb74606a989e290a57497b83d319a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedContentModuleBannerTiles_browseContentModule$data = {
  readonly banners: ReadonlyArray<{
    readonly ctaUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedContentModuleBannerItem_banner">;
  } | null> | null;
  readonly termsLink: string | null;
  readonly tiles: ReadonlyArray<{
    readonly content: string | null;
    readonly imageUrl: string | null;
    readonly linkData: {
      readonly path: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
    };
    readonly localizedLinkDataList: ReadonlyArray<{
      readonly currency: string | null;
      readonly linkData: {
        readonly path: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
      };
    } | null> | null;
    readonly title: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedContentModuleBannerTiles_browseContentModule";
};
export type SbSharedContentModuleBannerTiles_browseContentModule$key = {
  readonly " $data"?: SbSharedContentModuleBannerTiles_browseContentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedContentModuleBannerTiles_browseContentModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SeoLink_linkData"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedContentModuleBannerTiles_browseContentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "termsLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrowseContentModuleBannerType",
      "kind": "LinkedField",
      "name": "banners",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedContentModuleBannerItem_banner"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrowseContentModuleTile",
      "kind": "LinkedField",
      "name": "tiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalizedLinkData",
          "kind": "LinkedField",
          "name": "localizedLinkDataList",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentModuleBannerTiles",
  "abstractKey": null
};
})();

(node as any).hash = "3cf7b4368d069d1484c47048e0775829";

export default node;
