import { type FC, type ReactNode, useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { Link } from 'dibs-elements/exports/Link';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';

import { Carousel } from 'dibs-carousel';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { trackEcommerce, trackEvent, eventNameConstants } from 'dibs-tracking';
import { getSrcsetUrl } from 'dibs-image-utils/exports/srcSet';
import { SharedCarouselDot } from '../../shared/SharedCarouselDot/SharedCarouselDot';
import { SbSharedContentModuleBannerItem } from './SbSharedContentModuleBannerItem';

import { type SbSharedContentModuleBannerTiles_browseContentModule$key } from './__generated__/SbSharedContentModuleBannerTiles_browseContentModule.graphql';

import styles from './SbSharedContentModuleBannerTiles.scss';

function fireTracking(): void {
    trackEvent({
        category: 'ecommerce',
        action: 'promotion click',
        label: 'collections banner tiles',
    });
}

export const trackInteraction = (label: string): void => {
    trackEvent({
        category: 'promo interaction',
        action: 'banner displayed',
        label,
    });
};

const BannerWrapper: FC<{ href: string; children: ReactNode }> = ({ href, children }) => {
    if (href) {
        return (
            <Link underline="none" href={href}>
                {children}
            </Link>
        );
    }
    return <>{children}</>;
};

type Props = {
    isSalePage: boolean;
    browseContentModule: SbSharedContentModuleBannerTiles_browseContentModule$key;
    renderImage?: ({
        src,
        alt,
        className,
        srcSet,
        sizes,
    }: {
        src: string;
        alt: string;
        className: string;
        srcSet: string;
        sizes?: string;
    }) => ReactNode;
};

export const SbSharedContentModuleBannerTiles: FC<Props> = ({
    isSalePage,
    browseContentModule: browseContentModuleRef,
    renderImage = ({ src, alt, className, srcSet, sizes }) => (
        <img src={src} alt={alt} className={className} srcSet={srcSet} sizes={sizes} />
    ),
}) => {
    const { currency } = useCurrency();
    const browseContentModule = useFragment(
        graphql`
            fragment SbSharedContentModuleBannerTiles_browseContentModule on ContentModuleBannerTiles {
                termsLink
                banners {
                    ...SbSharedContentModuleBannerItem_banner
                    ctaUrl
                }
                tiles {
                    imageUrl
                    title
                    content
                    linkData {
                        ...SeoLink_linkData
                        path
                    }
                    localizedLinkDataList {
                        currency
                        linkData {
                            ...SeoLink_linkData
                            path
                        }
                    }
                }
            }
        `,
        browseContentModuleRef
    );
    const { tiles, termsLink, banners } = browseContentModule || {};
    const bannersLength = banners?.length || 0;
    const tilesLength = tiles?.length;

    useEffect(() => {
        if (isSalePage && tilesLength) {
            trackInteraction('cms sale tile');
        }
    }, [isSalePage, tilesLength]);

    if (!bannersLength) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <InViewport>
                {({ inViewport }) => (
                    <Carousel
                        isAutoRun={inViewport && bannersLength > 1}
                        classNames={{ wrapper: styles.carousel }}
                        dataTn="hero-items-carousel"
                        showDots={bannersLength > 1}
                        hideArrows
                        totalItems={bannersLength}
                        itemsPerPage={1}
                        step={1}
                        renderItem={({ index }: { index: number }) =>
                            banners?.[index] ? (
                                <BannerWrapper href={banners[index]?.ctaUrl || ''}>
                                    <SbSharedContentModuleBannerItem
                                        isSalePage={isSalePage}
                                        banner={banners[index]}
                                        renderImage={renderImage}
                                        termsLink={termsLink || ''}
                                    />
                                </BannerWrapper>
                            ) : null
                        }
                        renderDot={({ isCurrentDot }: { isCurrentDot?: boolean } = {}) => (
                            <SharedCarouselDot isActive={!!isCurrentDot} />
                        )}
                    />
                )}
            </InViewport>
            <div className={styles.tilesWrapper}>
                {tiles?.map((tile, index): ReactNode => {
                    const { title, content, imageUrl } = tile || {};
                    const localizedLinkData = tile?.localizedLinkDataList?.find(
                        item => item?.currency === currency
                    );
                    const linkData = localizedLinkData?.linkData || tile?.linkData;

                    if (title && content && linkData) {
                        return (
                            <SeoLink
                                ariaLabel={title || ''}
                                dataTn={`browse-cms-tile-${index}`}
                                onClick={() => {
                                    if (isSalePage) {
                                        trackEcommerce({
                                            type: 'promoClick',
                                            eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
                                            promotions: [
                                                {
                                                    id: `browse-cms-tile-${index + 1}`,
                                                    name: 'cms sale tile',
                                                    creative: linkData?.path || '',
                                                    position: index + 1,
                                                },
                                            ],
                                        });
                                    } else {
                                        fireTracking();
                                    }
                                }}
                                className={styles.tile}
                                key={`browse-cms-tile-${index}`}
                                linkData={linkData}
                            >
                                {renderImage({
                                    src: imageUrl || '',
                                    alt: title,
                                    className: styles.tileImage,
                                    srcSet: `
                                            ${getSrcsetUrl(imageUrl || '', 72)} 1x,
                                            ${getSrcsetUrl(imageUrl || '', 144)} 2x,
                                        `,
                                })}
                                <span
                                    className={styles.tileTitle}
                                    dangerouslySetInnerHTML={{ __html: content }}
                                />
                            </SeoLink>
                        );
                    }

                    return null;
                })}
            </div>
        </div>
    );
};
