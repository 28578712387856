/**
 * @generated SignedSource<<415d7085edc5c49bee7c1583e3f9cd64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRelatedCreator_relatedCreators$data = {
  readonly creatorName: string | null;
  readonly items: ReadonlyArray<{
    readonly localizedPdpUrl?: string | null;
    readonly photos?: ReadonlyArray<{
      readonly masterOrZoomPath: string | null;
    } | null> | null;
    readonly title?: string | null;
  } | null> | null;
  readonly linkData: {
    readonly path: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly shopAllTitle: string | null;
  readonly " $fragmentType": "SbSharedRelatedCreator_relatedCreators";
};
export type SbSharedRelatedCreator_relatedCreators$key = {
  readonly " $data"?: SbSharedRelatedCreator_relatedCreators$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRelatedCreator_relatedCreators">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRelatedCreator_relatedCreators",
  "selections": [
    {
      "alias": "shopAllTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "creatorName",
      "args": null,
      "kind": "ScalarField",
      "name": "pageObjectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "localizedPdpUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "limit",
                  "value": 1
                }
              ],
              "concreteType": "ItemPhoto",
              "kind": "LinkedField",
              "name": "photos",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "masterOrZoomPath",
                  "storageKey": null
                }
              ],
              "storageKey": "photos(limit:1)"
            }
          ],
          "type": "Item",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RelatedCreatorType",
  "abstractKey": null
};

(node as any).hash = "7d3ecc794622c018e615e452553bd58e";

export default node;
