/**
 * @generated SignedSource<<a7bc2c1cfcf5a285bb9eb75f0bffca71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespDesignTiles_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespDesignsDescriptionTile_itemSearch">;
  readonly " $fragmentType": "SbRespDesignTiles_itemSearch";
};
export type SbRespDesignTiles_itemSearch$key = {
  readonly " $data"?: SbRespDesignTiles_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespDesignTiles_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespDesignTiles_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespDesignsDescriptionTile_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "59ab0439e72c2fc78f233abc4b6a7c24";

export default node;
