/**
 * @generated SignedSource<<f0be49a134d9babceffda597f86d2b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRelatedPages_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
    } | null> | null;
  } | null> | null;
  readonly filters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
    } | null> | null;
  } | null> | null;
  readonly relatedPages: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SharedMobileLinksBlock_links" | "SharedRespLinksBlock_links">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SharedMobileLinksBlock_itemSearch" | "SharedRespLinksBlock_itemSearch">;
  readonly " $fragmentType": "SharedRelatedPages_itemSearch";
};
export type SharedRelatedPages_itemSearch$key = {
  readonly " $data"?: SharedRelatedPages_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRelatedPages_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRelatedPages_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseFilterValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRespLinksBlock_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileLinksBlock_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalBuyLinkType",
      "kind": "LinkedField",
      "name": "relatedPages",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedRespLinksBlock_links"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedMobileLinksBlock_links"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "86f08ef4454623ba8092314a5cf4448c";

export default node;
