import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import { Button } from 'dibs-elements/exports/Button';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import { type SbSharedContentModuleBannerText_banner$key } from './__generated__/SbSharedContentModuleBannerText_banner.graphql';

import styles from './SbSharedContentModuleBannerText.scss';

type Props = {
    banner: SbSharedContentModuleBannerText_banner$key | null | undefined;
    isStaticBanner: boolean;
};

export const SbSharedContentModuleBannerText: FC<Props> = ({
    banner: bannerRef,
    isStaticBanner,
}) => {
    const {
        textAlignment,
        textColor,
        eyeBrowText,
        title,
        description,
        ctaUrl,
        ctaStyle,
        ctaCopy,
        backgroundColor,
    } =
        useFragment(
            graphql`
                fragment SbSharedContentModuleBannerText_banner on BrowseContentModuleBannerType {
                    title
                    description
                    eyeBrowText
                    ctaUrl
                    ctaCopy
                    ctaStyle
                    textAlignment
                    textColor
                    backgroundColor
                }
            `,
            bannerRef
        ) || {};

    const { isMobile } = useServerVarsContext();
    const isWhiteText = (textColor === 'white' && !!backgroundColor) || isStaticBanner;
    const textClasses = classnames(styles.text, {
        [styles.captionAlignCenter]: textAlignment === 'center' || isMobile,
        [styles.captionColor]: isWhiteText,
        [styles.staticWrapper]: isStaticBanner,
    });
    const ctaClasses = classnames(styles.cta, styles.important, {
        [styles.ctaOutline]: ctaStyle === 'outlined',
        [styles.ctaColor]: isWhiteText,
    });

    if (!title || !description) {
        return null;
    }

    return (
        <div className={textClasses}>
            {eyeBrowText && <p className={styles.eyeBrow}>{eyeBrowText}</p>}
            <HeadingLevel>
                {Heading => <Heading className={styles.header}>{title}</Heading>}
            </HeadingLevel>
            <div
                className={styles.bodyText}
                dangerouslySetInnerHTML={{ __html: description || '' }}
            />
            {ctaCopy && ctaUrl && (
                <Button dataTn="hero-banner-cta" className={ctaClasses} type="secondary">
                    {ctaCopy}
                </Button>
            )}
        </div>
    );
};
