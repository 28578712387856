/**
 * @generated SignedSource<<854f88c6f76d5df82bbb94bbfdc3de38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespContentModuleRegular_browseContentModule$data = {
  readonly description: string | null;
  readonly photo: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedStorefrontLink_browseContentModule">;
  readonly " $fragmentType": "SbRespContentModuleRegular_browseContentModule";
};
export type SbRespContentModuleRegular_browseContentModule$key = {
  readonly " $data"?: SbRespContentModuleRegular_browseContentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespContentModuleRegular_browseContentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespContentModuleRegular_browseContentModule",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedStorefrontLink_browseContentModule"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photo",
      "storageKey": null
    }
  ],
  "type": "ContentModuleRegular",
  "abstractKey": null
};

(node as any).hash = "fee0dd1de42059f9bdbf2a63c52ae239";

export default node;
