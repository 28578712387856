/**
 * @generated SignedSource<<22bb514fd2f01b7b0f9fc580145fe79a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedContentModuleBannerText_banner$data = {
  readonly backgroundColor: string | null;
  readonly ctaCopy: string | null;
  readonly ctaStyle: string | null;
  readonly ctaUrl: string | null;
  readonly description: string | null;
  readonly eyeBrowText: string | null;
  readonly textAlignment: string | null;
  readonly textColor: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "SbSharedContentModuleBannerText_banner";
};
export type SbSharedContentModuleBannerText_banner$key = {
  readonly " $data"?: SbSharedContentModuleBannerText_banner$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedContentModuleBannerText_banner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedContentModuleBannerText_banner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeBrowText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaCopy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textAlignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    }
  ],
  "type": "BrowseContentModuleBannerType",
  "abstractKey": null
};

(node as any).hash = "10794e460008603e90b663a7ba617a9d";

export default node;
