/**
 * @generated SignedSource<<775afd77460bf243e94e9d566ead70db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbSharedRelatedCreators_itemSearch$data = {
  readonly meta: {
    readonly attributeDisplayName: string | null;
  } | null;
  readonly relatedCreators: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly linkData: {
          readonly path: string | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"SbSharedRelatedCreator_relatedCreators">;
      } | null;
    } | null> | null;
  } | null;
  readonly topCategoryL1: VerticalType | null;
  readonly " $fragmentType": "SbSharedRelatedCreators_itemSearch";
};
export type SbSharedRelatedCreators_itemSearch$key = {
  readonly " $data"?: SbSharedRelatedCreators_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRelatedCreators_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRelatedCreators_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attributeDisplayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topCategoryL1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "relatedCreatorsConnection",
      "kind": "LinkedField",
      "name": "relatedCreators",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "relatedCreatorsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RelatedCreatorType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LinkData",
                  "kind": "LinkedField",
                  "name": "linkData",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "path",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SbSharedRelatedCreator_relatedCreators"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "f3166e808963f0465a2f12ac2060ad93";

export default node;
