import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { trackEvent } from 'dibs-tracking';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import classNames from 'classnames';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbRespTilesSwiper } from '../SbRespTilesSwiper/SbRespTilesSwiper';
import { SbRespDesignsDescriptionTile } from './SbRespDesignsDescriptionTile';
import { type SbRespDesignTiles_itemSearch$data } from './__generated__/SbRespDesignTiles_itemSearch.graphql';
import { type SbRespDesignTiles_designPageInfo$data } from './__generated__/SbRespDesignTiles_designPageInfo.graphql';

import styles from './SbRespDesignTiles.scss';

type Props = {
    itemSearch: SbRespDesignTiles_itemSearch$data;
    designPageInfo?: SbRespDesignTiles_designPageInfo$data;
    onViewMoreClick?: () => void;
};

const FULL_ROW_LENGTH = 4;

const SbRespDesignTilesComponent: FC<Props> = ({ itemSearch, designPageInfo, onViewMoreClick }) => {
    const { displayUriRef } = itemSearch || {};
    const { creatorDesigns } = designPageInfo || {};
    const { isTablet } = useServerVarsContext();
    if (!creatorDesigns || creatorDesigns.length < 2) {
        return null;
    }
    const firstFourDesigns = creatorDesigns.slice(0, FULL_ROW_LENGTH);
    const hasRelatedDesigns = designPageInfo?.creatorDesigns?.length;

    return (
        <div
            className={classNames(styles.container, {
                [styles.hasFullRowOfTiles]: firstFourDesigns.length === FULL_ROW_LENGTH,
            })}
        >
            <SbRespTilesSwiper displayUriRef={displayUriRef || ''} isTablet={isTablet}>
                <SbRespDesignsDescriptionTile
                    itemSearch={itemSearch}
                    onViewMoreClick={hasRelatedDesigns ? onViewMoreClick : undefined}
                />
                {firstFourDesigns.map((design, index: number) => {
                    const { displayName, browsePageLinkData, imageUrl } = design || {};
                    if (!displayName || !browsePageLinkData || !imageUrl) {
                        return null;
                    }
                    return (
                        <SeoLink
                            className={styles.tile}
                            linkData={browsePageLinkData}
                            key={displayName}
                            dataTn={`design-tile-link-${index}`}
                            onClick={() =>
                                trackEvent({
                                    category: 'promo interaction',
                                    action: 'more from iconic design creator clicked',
                                    label: 'creator page',
                                })
                            }
                        >
                            <div className={styles.imageWrapper}>
                                <img
                                    className={styles.image}
                                    src={imageUrl}
                                    alt={displayName}
                                    srcSet={getSrcsetString(imageUrl, [200, 320])}
                                    sizes="130px"
                                />
                            </div>
                            <div className={styles.title}>{displayName}</div>
                        </SeoLink>
                    );
                })}
            </SbRespTilesSwiper>
        </div>
    );
};

export const SbRespDesignTiles = createFragmentContainer(SbRespDesignTilesComponent, {
    itemSearch: graphql`
        fragment SbRespDesignTiles_itemSearch on ItemSearchQueryConnection {
            ...SbRespDesignsDescriptionTile_itemSearch
            displayUriRef
        }
    `,
    designPageInfo: graphql`
        fragment SbRespDesignTiles_designPageInfo on DesignPageInfo {
            creatorDesigns {
                url
                displayName
                imageUrl
                browsePageLinkData {
                    ...SeoLink_linkData
                }
            }
        }
    `,
});
