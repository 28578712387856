/**
 * @generated SignedSource<<224e5a1c4bf8d44078fd62e71686aac6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedContentModuleBannerImage_banner$data = {
  readonly ctaCopy: string | null;
  readonly mainImage: string | null;
  readonly " $fragmentType": "SbSharedContentModuleBannerImage_banner";
};
export type SbSharedContentModuleBannerImage_banner$key = {
  readonly " $data"?: SbSharedContentModuleBannerImage_banner$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedContentModuleBannerImage_banner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedContentModuleBannerImage_banner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaCopy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainImage",
      "storageKey": null
    }
  ],
  "type": "BrowseContentModuleBannerType",
  "abstractKey": null
};

(node as any).hash = "d3a854d0d398e36f78e6c4bd5f63094c";

export default node;
